// Description: Base API class for all API classes

import Vue from 'vue'
import http from '_main/http'

import { saveAs } from "file-saver";
import { unparse } from "papaparse";


import AsyncComputed from "vue-async-computed"
Vue.use(AsyncComputed)

export const defaultData = {}

if (!Vue.prototype.$api) { Vue.prototype.$api = {} }
export default {
  data() {
    return {
      config: {},
      paginationInfo: {},
      params: {},
      page: 1,
      perPage: 100,
      sortInfo: [],
      loading: false,
      loadingStartedAt: null,
      loadingFinishedAt: null,
    }
  },
  computed: {
    whereParams() {
      return {
        ...this.params,
        page: this.page,
        perPage: this.perPage,
        sortInfo: this.sortInfo
      }
    }
  },
  asyncComputed: {
    records: {
      lazy: true,
      default: [],
      get() {
        return this.where(this.whereParams)
      }
    },
    all: {
      lazy: true,
      default: [],
      get() {
        return this.where({...this.whereParams, perPage: 'all'})
      }
    }
  },
  watch: {
    params: {
      handler() {
        this.setPage(1)
      },
      deep: true
    }
  },
  methods: {
    setPage(page) {
      this.page = page
    },
    setOptionsParam(param) {
      let exclusiveOptions = ['any', 'assigned', 'none', 'active', 'inactive']
      let options = utils.dig(this.params, param)
      let lastOption = utils.last(options)
      if (exclusiveOptions.includes(lastOption)) {
        Vue.set(this.params, param, [lastOption])
      } else {
        Vue.set(this.params, param, utils.pull(options, ...exclusiveOptions))
      }
    },
    addAllBoardStages(board) {
      this.params.stages = this.params.stages.concat(board.stages.map(stage => stage.id))
      this.setOptionsParam('stages')
    },
    sortBy(sortInfo) {
      this.sortInfo=sortInfo
    },
    where(params={}) {
      this.loadingStarted()
      return http.get(this.config.recordsPath(params)).then(response => {
        this.paginationInfo = response.data.pagination
        this.loadingFinished()
        return response.data[this.config.recordsKey] || response.data['records'];
      })
    },
    search(searchTerm) {
      this.params.searchTerm = searchTerm
      return this.where(this.whereParams)
    },
    fetchRecords() {
      this.$asyncComputed.records.update()
    },
    fetchAllRecords() {
      this.$asyncComputed.all.update()
    },
    exportCSV() {
      this.loadingStarted()
      let exportName = `${this.$options.name || 'export'}.csv`
      app.$notices.send(`${exportName} export started`)
      this.where({...this.whereParams, perPage: 'all'}).then(records => {
        let csv = this.csvRecords(records)
        // this.log(csv)
        // this.loadingFinished()

        csv = "\ufeff" + csv

        let blob = new Blob([csv], {
          type: "application/csvcharset=utf-8"
        });

        // app.log(exportName)
        saveAs(blob, `${this.$options.name || 'export'}.csv`)
      }).catch(() => {
        app.$errors.send(`${exportName} export failed!`)
      }).finally(() => {
        this.loadingFinished()
      })
    },
    csvRecords(records) {
      return unparse(records)
    },
    init(record) {
      return _.merge({}, this.defaultData, record)
    },
    fetch(id) {
      // TODO make this better, ideally we would fetch from the collection and fall back to the find method if not found
      return this.all.find( record => record.id == id )
    },
    find(id, params={}) {
      if (!id) {
        this.$errors.send('Record ID is null')
        return Promise.reject('Record ID is null')
      }
      return http.get(this.config.recordPath(id, params)).then(response => {
        this.checkAppVersion(response.data.appVersion)
        return response.data[this.config.recordKey]
      }).catch(error => {
        this.$errors.send('Error Finding Record')
        return Promise.reject(error)
      })
    },
    save(record) {
      let submitPath = this.config.submitPath || this.config.recordsPath
      return http
        .post(submitPath(), {
          [this.config.recordKey || 'record']: utils.toJSON(record)
        })
        .then(response => {
          return response.data[this.config.recordKey]
        })
        .catch(error => {
          this.$errors.send('Error Saving Record')
          error.response.data.errors.forEach (error => {
            this.$errors.send(error)
          })
          return Promise.reject(error)
        })
    },
    destroy(id) {
      return http.delete(this.config.recordPath(id)).then(response => {
        this.$emit("destroyed", id)
        return response.data[this.config.recordKey]
      })
    },
    archive(id) {
      return http.post(this.config.archivePath(id)).then(response => {
        this.$emit("archived", id)
        return response.data[this.config.recordKey]
      })
    },
    restore(id) {
      return http.post(this.config.restorePath(id)).then(response => {
        this.$emit("restored", id);
        return response.data[this.config.recordKey]
      });
    },
    loadingStarted(timeout=5) {
      this.loading = true
      this.loadingStartedAt = new Date()
      setTimeout(() => this.loadingFinished(), timeout * 1000);
    },
    loadingFinished() {
      this.loading = false
      this.loadingFinishedAt = new Date()
      // this.log('Loading Time', this.$options.name, this.loadingFinishedAt - this.loadingStartedAt)
    },
    checkAppVersion(appVersion) {
      if(!appVersion) {return}
      let localVersion = localStorage.getItem('version')
      localStorage.setItem('version', appVersion)
      app.log('Checking App Version', localVersion, appVersion)

      if (localVersion != appVersion) {
        app.$notices.send('Please refresh your browser to get the latest version of Shopflow.', {duration: 5000})
        app.log('Version Changed!', localVersion, appVersion)
        // app.$router.go()
      }
    }
  },
  created() {
    if (this.config.fetchRecords) {
      this.log('Fetching Records', this.config)
    }
  }
}
